<template>
	<main>
		<section 
			class="min-vh-100 d-flex align-items-center section-image overlay-soft-dark"
		>
			<div class="absolute-container">
				<router-view v-slot="{ Component }">
					<transition name="fade" mode="out-in">
						<component :is="Component" />
					</transition>
				</router-view>
			</div>
		</section>

	</main>
</template>

<script>
	export default {

		name: 'FormParent',
		data () {
			return {

			}
		}
	}
</script>

<style lang="css" scoped>
	.section-image
	{
		background: url("../../assets/img/hero-bg.jpg") top center;
	}
	.absolute-container
	{
		position: absolute;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    z-index: 999;
	    width: 100vw;
	    height: 100vh;
	    display: flex;
    	align-items: center;
	}
</style>