<template>
    <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
            <component :is="Component" />
        </transition>
    </router-view>
    <Loading />
</template>

<script>
    import Loading from './components/shared/Loading'
    export default {

        name: 'Index',
        components: {
            Loading
        },
        data () {
            return {

            }
        }
    }
</script>

<style lang="scss">
	//@import './assets/sass/_shared.scss'
</style>