<template>
	
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 d-flex align-items-center justify-content-center">
				<div class="signin-inner my-4 my-lg-0 bg-white shadow-soft border rounded border-gray-300 p-4 p-lg-5 w-100 fmxw-500">
					<div class="logo-svg-container">
						<router-link :to="{ name: 'Home' }">
							<LogoSVG/>
						</router-link>
					</div>
					<div class="text-center text-md-center mb-4 mt-md-0">
						<h1 class="mb-0 h3">Create an account</h1>
					</div>
					<form @submit.prevent="Register">
						<div class="form-group mb-4">
							<label for="email">Your Email</label>
								<div class="input-group">
									<span class="input-group-text" id="basic-addon3">
										<i class="bi bi-envelope-fill"></i>
									</span> 
									<input 
										type="email" 
										class="form-control" 
										placeholder="example@company.com" 
										id="email" 
										v-model="email"
										:class="{ 'is-invalid': v$.email.$error }"
									>
									<div class="invalid-feedback" v-if="!v$.email.required.$invalid">
				  						Required
				  					</div>
				  					<div class="invalid-feedback" v-if="!v$.email.email.$invalid">
				  						Must be a valid email
				  					</div>
								</div>
							</div>
							<div class="form-group">
							<div class="form-group mb-4">
								<label for="name">Your Name</label>
								<div class="input-group">
									<span class="input-group-text" id="basic-addon4">
										<i class="bi bi-person-fill"></i>
									</span> 
									<input 
										type="text" 
										placeholder="John Snow"
										class="form-control" 
										id="name"
										v-model="name"
										:class="{ 'is-invalid': v$.name.$error }"
									>
									<div class="invalid-feedback">
				  						Required
				  					</div>
								</div>
							</div>
							<div class="form-check mb-4">
								<input 
									class="form-check-input" 
									type="checkbox"
									id="terms" 
									v-model="agreed_terms"
									:class="{ 'is-invalid': v$.agreed_terms.$error }"
								> 
								<label class="form-check-label" for="terms">
									I agree to the <a href="javascript:;">terms and conditions</a>
								</label>
								<div class="invalid-feedback">
							        You must agree before submitting.
						        </div>
							</div>
						</div>
						<div class="d-grid">
							<button type="submit" class="btn btn-primary">Sign up</button>
						</div>
					</form>
					<div class="mt-3 mb-4 text-center">
						<span class="fw-normal">or</span>
					</div>
					<div class="btn-wrapper my-4 text-center">
						<a href="#" class="btn btn-icon-only btn-pill btn-outline-gray-300 text-facebook me-2" aria-label="facebook button" title="facebook button">
							<i class="bi bi-facebook"></i>
						</a>
						<a href="#" class="btn btn-icon-only btn-pill btn-outline-gray-300 text-twitter me-2" aria-label="twitter button" title="twitter button">
							<i class="bi bi-google"></i>
						</a>
					</div>
					<div class="d-flex justify-content-center align-items-center mt-4">
						<span class="fw-normal">Already have an account? 
							<router-link :to="{ name: 'Login' }" class="fw-bold text-underline">Login here</router-link></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	import useVuelidate from '@vuelidate/core'
	import { required, email, sameAs } from '@vuelidate/validators'
	export default {

		name: 'Register',
		setup () 
		{
		    return { v$: useVuelidate() }
		},
		data () {
			return {
				email: 			'',
				name: 			'',
				agreed_terms: 	false
			}
		},
		validations()
		{
			return {
				email: 	{
					required,
					email
				},
				name: {
					required
				},
				agreed_terms: {
					sameAs: sameAs(true)
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			Register()
			{
				this.v$.$touch()

				if(!this.v$.$invalid)
				{
					const register_url 	= `${process.env.VUE_APP_API_URL}/api/landingpage/register`

					this.StartLoading()

					window.axios.post(register_url,{
							email: 	this.email,
							name: 	this.name
						})
						.then(({data}) => {
							if(data.response)
							{
								if(data.message && data.message == 'email_resend')
								{
									Swal.fire({
										title:	'Email resent',
										text: 	'Check your mailbox to continue this process.',
										icon: 	'info'
									}).then((result) => {
										this.ClearRegister()
									})

								}else{

									Swal.fire({
										title:	'Email sent',
										text: 	'Check your mailbox to continue this process.',
										icon: 	'info'
									}).then((result) => {
										this.ClearRegister()
									})
								}

							}else{

								throw new Error(data.message)
							}
						})
						.catch(error => {
							switch(error)
							{
								case 'email_already_registered':

									Swal.fire({
										title:	'Opss...',
										text: 	'This email is already registered.',
										icon: 	'error'
									}).then((result) => {
										this.ClearRegister()
									})

								break

								default:

									this.ClearRegister()

									Swal.fire({
										title:	'Opss..',
										text: 	'An error has occurred, contact support.',
										icon: 	'error'
									})
								break
							}
						})
						.finally(() => {
							this.FinishLoading()
						})
				}
			},
			ClearRegister()
			{
				this.name 			= ''
				this.email 			= ''
				this.agreed_terms 	= false

				this.v$.$reset()
			},
		}
	}
</script>

<style lang="css" scoped>
</style>