import { createApp } from 'vue'
import LogoSVG from './components/layout/LogoSVG'
import VueTheMask from 'vue-the-mask'
import store from './vuex'
import 'bootstrap';

import Helpers from './helpers'
window.helpers 		= new Helpers()

window._ 			= require('lodash')

window.axios        = require('axios');

window.axios.defaults.headers.common['X-Requested-With']   = 'XMLHttpRequest';

window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

require('./global-variables')
require('./directives')

import router from './routes'

import App from './Index.vue'

const app 			= createApp(App)
	.component('LogoSVG', LogoSVG)
	.use(router)
	.use(store)
	.use(VueTheMask)
	.mount('#app')
