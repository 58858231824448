<template>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-8">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-12 text-center">
								<div class="logo-svg-container mt-0">
									<router-link :to="{ name: 'Home' }">
										<LogoSVG/>
									</router-link>
								</div>
							</div>
						</div>
						<div class="row" v-if="user">
							<div class="col-12 col-md-6 offset-md-3 ">
								<div class="form-group">
									<label>Put your password to continue.</label>
									<input 
										type="password"
										v-model="v$.form.password.$model"
										class="form-control"
										:class="{ 'is-invalid': v$.form.password.$error}" 
									>
									<div class="invalid-feedback">
										Required
									</div>
								</div>
							</div>
							<div class="col-12 text-center mt-3">
								<button
									type="button"
									class="btn btn-tertiary px-5"
									@click="Save"
								>
									Continue
								</button>
							</div>
						</div>
						<div class="row" v-else>
							<div class="col-12">
								<h5 class="mt-3 title-1 text-center">Validating...</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>

	import { mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	import useVuelidate from '@vuelidate/core'
	import { required, email, sameAs, minLength } from '@vuelidate/validators'

	export default {

		name: 'ValidateEmail',
		props: {
			token: 	{
				type: 		String,
				default: 	null
			}
		},
		setup () 
		{
		    return { v$: useVuelidate() }
		},
		data () {
			return {
				user: 				null,
				form: 				{
					password: 			'',
					user_id: 			'',
					email: 				''
				}
			}
		},
		validations: {
			form: 		{
				password: 	{
					required
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			ValidateMailToken()
			{
				this.StartLoading()

				const vm 	= this

				return window.axios.get(`${process.env.VUE_APP_API_URL}/api/landingpage/validate-mail-token/${vm.token}`)
					.then(({data}) => {

						if(data.response)
						{	
							this.FinishLoading()

							vm.user 			= data.user
							vm.form.email 		= data.email
							vm.form.user_id 	= data.user.id

						}else{

							throw new Error(data.message)
						}
					})
					.catch((error) => {

						Swal.fire({
							icon: 	'error',
							title: 	'Oops...',
							text: 	'Invalid token, contact support.'
						})
						.then(() => {
							vm.$router.push({ name: 'Home' })
						})
					})
			},
			async Save()
			{
				const vm 	= this

				vm.v$.form.$touch()

				if(!vm.v$.form.$invalid)
				{
					vm.StartLoading()

					window.axios.post(`${process.env.VUE_APP_API_URL}/api/landingpage/validate-and-change-my-email`, {
							...vm.form,
							bridge_key: 	vm.token
						})
						.then(({data}) => {
							if(data.response)
							{
								Swal.fire({
									icon: 	'success',
									title: 	'Success !',
									text: 	'E-mail changed successfully.',
									showConfirmButton: 	true,
									preConfirm: () => {
										vm.$router.push({ name: 'Home' })
									}
								})

							}else{

								Swal.fire({
									icon: 	'error',
									title: 	'Oops...',
									text: 	'Wrong password, try again.'
								})
							}
						})
						.catch(({response}) => {

							Swal.fire({
								icon: 	'error',
								title: 	'Ops...',
								text: 	'An error has occurred on trying to complete validation, contact support.'
							})
						})
						.finally(() => {
							vm.FinishLoading()
						})
				}
			}
		},
		beforeMount()
		{
			this.ValidateMailToken()
		}
	}
</script>

<style lang="css" scoped>


</style>