const StartLoading = ({ commit }) => {
	commit('ADD_RUNNING_REQUEST')
}

const FinishLoading = ({ commit }) => {
	commit('REM_RUNNING_REQUEST')
}


export {
	StartLoading,
	FinishLoading
}