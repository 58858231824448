<template>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 d-flex align-items-center justify-content-center">
				<div class="signin-inner my-4 my-lg-0 bg-white shadow-soft border rounded border-gray-300 p-4 p-lg-5 w-100 fmxw-500">
					<div class="logo-svg-container">
						<router-link :to="{ name: 'Home' }">
							<LogoSVG/>
						</router-link>
					</div>
					<div class="text-center text-md-center mb-4 mt-md-0">
						<h1 class="mb-0 h3">Sign in to our platform</h1>
					</div>
					<form class="mt-4" @submit.prevent="Login">
						<div class="form-group mb-4">
							<label for="email">Your Email</label>
							<div class="input-group">
								<span class="input-group-text" id="basic-addon1">
									<i class="bi bi-envelope-fill"></i>
								</span> 
								<input 
									type="email" 
									class="form-control" 
									placeholder="example@company.com" 
									id="email" 
									v-model="email"
									:class="{ 'is-invalid': v$.email.$error }"
								>
								<div class="invalid-feedback" v-if="!v$.email.required.$invalid">
			  						Required
			  					</div>
			  					<div class="invalid-feedback" v-if="!v$.email.email.$invalid">
			  						Must be a email
			  					</div>
							</div>
						</div>
						<div class="form-group">
							<div class="form-group mb-4">
								<label for="password">Your Password</label>
								<div class="input-group">
									<span class="input-group-text" id="basic-addon2">
										<i class="bi bi-unlock-fill"></i>
									</span> 
									<input 
										type="password" 
										placeholder="Password" 
										class="form-control" 
										id="password" 
										v-model="password"
										:class="{ 'is-invalid': v$.password.$error }"
									>
									<div class="invalid-feedback" v-if="!v$.password.required.$invalid">
				  						Required
				  					</div>
				  					<div class="invalid-feedback" v-if="!v$.password.minLength.$invalid">
				  						Min 6 characters
				  					</div>
								</div>
							</div>
							<div class="d-flex justify-content-between align-items-center mb-4">
								<div class="form-check mb-0">
									<input class="form-check-input" type="checkbox" value="" id="remember"> 
									<label class="form-check-label mb-0" for="remember">Remember me</label>
								</div>
								<div>
									<a 
										href="javascript:;" 
										class="small text-right"
										@click="RecoveryPasswd"
									>
										Lost password?
									</a>
								</div>
							</div>
						</div>
						<div class="d-grid">
							<button type="submit" class="btn btn-primary">Sign in</button>
						</div>
					</form>
					<div class="mt-3 mb-4 text-center">
						<span class="fw-normal">or login with</span>
					</div>
					<div class="btn-wrapper my-4 text-center">
						<a href="#" class="btn btn-icon-only btn-pill btn-outline-gray-300 text-facebook me-2" aria-label="facebook button" title="facebook button">
							<i class="bi bi-facebook"></i>
						</a>
						<a href="#" class="btn btn-icon-only btn-pill btn-outline-gray-300 text-twitter me-2" aria-label="twitter button" title="twitter button">
							<i class="bi bi-google"></i>
						</a>
					</div>
					<div class="d-flex justify-content-center align-items-center mt-4">
						<span class="fw-normal">Not registered? 
							<router-link :to="{ name: 'Register'}" class="fw-bold text-underline">Create account</router-link>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	import useVuelidate from '@vuelidate/core'
	import { required, email, minLength } from '@vuelidate/validators'
	export default {

		name: 'Login',
		setup () 
		{
		    return { v$: useVuelidate() }
		},
		data () {
			return {
				email: 		'',
				password: 	''
			}
		},
		validations()
		{
			return {
				email: 	{
					required,
					email
				},
				password: {
					required,
					minLength: minLength(6)
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			Login()
			{
				this.v$.$touch()

				if(!this.v$.$invalid)
				{
					this.StartLoading()

					const login_url 		= `${process.env.VUE_APP_API_URL}/api/landingpage/login`

					window.axios.post(login_url, {
							email: 		this.email,
							password: 	this.password
						})
						.then(({data}) => {
							if(data.response)
							{
								const app_url 			= `${process.env.VUE_APP_APP_URL}/auth/token/${ data.bridge_key }`

								window.location.href 	= app_url

							}else{

								throw new Error(data)
							}
						})
						.catch(({response}) => {

							switch(response.data.message)
							{
								case 'wrong_credentials':

									Swal.fire({
										title:	'Opss..',
										text: 	'User and/or password invalid.',
										icon: 	'error'
									})

								break

								case 'inactive_user':

									Swal.fire({
										title:	'woow!',
										text: 	'Inactive user, contact support.',
										icon: 	'error'
									})

								break
								case 'unverified_email':

									Swal.fire({
										title:	'Opss..',
										text: 	'You need to check your mailbox.',
										icon: 	'error'
									})

								break
								default: 

									Swal.fire({
										title:	'Opss..',
										text: 	'An error has occurred, contact support.',
										icon: 	'error'
									})

								break
							}
						})
						.finally(() => {
							this.FinishLoading()
						})
				}
			},
			RecoveryPasswd()
			{
				const vm 				= this
				const recovery_url 		= `${process.env.VUE_APP_API_URL}/api/landingpage/try-to-restore-the-password`

				Swal.fire({
					title: 				'Password recovery',
					text: 				'Enter your login email to start the password recovery process, you will receive the next steps in your mailbox.',
					input: 				'text',
					customClass: 		{
						input: 				'form-control',
						confirmButton: 		'btn btn-tertiary'
					},
					
					confirmButtonText: 	'Send',
					cancelButtonText: 	'Cancel',
					showLoaderOnConfirm: true,
					preConfirm: (email) => {

						vm.StartLoading()

						return window.axios.post(recovery_url,{
								email: 	email
							})
							.then(({data}) => {

								if (!data.response) 
								{
									throw new Error(data.message)
								}

								return data
							})
							.catch(error => {
								Swal.showValidationMessage(
									`Request failed: ${error}`
								)
							})
							.finally(() => {
								vm.FinishLoading()
							})
					},
					allowOutsideClick: () => !Swal.isLoading()
				}).then(({value}) => {

					const data = value

					if (data && data.response) 
					{
						Swal.fire({
							title:	'Email sent',
							text: 	'Check your mailbox to continue this process.',
							icon: 	'success'
						})
					}
				})
			},
		}
	}
</script>

<style lang="css" scoped>

</style>