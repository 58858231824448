<template>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-8">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-12 text-center">
								<div class="logo-svg-container mt-0">
									<router-link :to="{ name: 'Home' }">
										<LogoSVG/>
									</router-link>
								</div>
							</div>
						</div>
						<transition name="fade" mode="out-in">
							<div class="row" v-if="step === 1" key="1">
								<div class="col-12 text-center">
									<h1 class="mb-0 h3">Continue your registration</h1>
								</div>
								<div class="col-12">
									<p class="text-center h5 my-5">
										Hi {{ user.name }}, you will follow the next steps to complete your registration on the platform.
									</p>
								</div>
								<div class="col-12 text-right">
									<button class="btn btn-tertiary ps-5" @click="step = 2">
										Continuar <i class="bi bi-arrow-right ms-3 me-4"></i>
									</button>
								</div>
							</div>
							<form 
								@submit.prevent="CompleteThisRegister" 
								v-else-if="step == 2" 
								key="2"
							>
								<div class="row">
									<div class="col-12 text-center mb-5">
										<h1 class="mb-0 h3">Complete this form</h1>
									</div>
									<div class="col-12">
										<div class="mb-3">
											<label>Your name</label>
											<input 
												type="text" 
												v-model="form.name"
												class="form-control"
												:class="{ 'is-invalid': v$.form.name.$error }"
											>
											<div class="invalid-feedback">
												Required
											</div>
										</div>
									</div>
									<div class="col-md-6 col-12">
										<div class="mb-3">
											<label>Your email</label>
											<input 
												type="email" 
												v-model="form.email"
												class="form-control"
												disabled
											>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="mb-3">
											<label>Your phone</label>
											<input 
												type="tel" 
												v-model="form.phone"
												class="form-control"
												:class="{ 'is-invalid': v$.form.phone.$error }"
												v-mask="['(##) ####-####', '(##) #####-####']"
											>
											<div class="invalid-feedback">
												Required
											</div>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="mb-3">
											<label>Your password</label>
											<input 
												type="password" 
												v-model="form.password"
												class="form-control"
												:class="{ 'is-invalid': v$.form.password.$error }"
											>
											<div class="invalid-feedback" v-if="!v$.form.password.required.$invalid">
												Required
											</div>
											<div class="invalid-feedback" v-if="!v$.form.password.minLength.$invalid">
												Minimum of 06 characters
											</div>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="mb-3">
											<label>Repeat your password</label>
											<input 
												type="password" 
												v-model="form.password_confirmation"
												class="form-control"
												:class="{ 'is-invalid': v$.form.password_confirmation.$error }"
											>
											<div class="invalid-feedback">
												Must be the same as the password
											</div>
										</div>
									</div>
									<div class="col-12 text-right mt-4">
										<button 
											class="btn btn-success px-5 text-white" 
											type="submit"
										>
											Concluir
										</button>
									</div>
								</div>

							</form>	
						</transition>					
					</div>
				</div>
			</div>
		</div>		
	</div>
</template>

<script>
	const CPFValidation = (value, vm) => window.helpers.ValidateCPF(value)

	import { mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	import useVuelidate from '@vuelidate/core'
	import { required, email, sameAs, minLength } from '@vuelidate/validators'
	export default {

		name: 'ValidateRegistration',
		props: {
			token: 	{
				type: 		[String, Number],
				default: 	null
			}
		},
		setup () 
		{
		    return { v$: useVuelidate() }
		},
		data () {
			return {
				step: 	1,
				user: 	{
					name: 	null
				},
				form: 	{
					email: 					'',
					name: 					'',
					phone: 					'',
					password: 				'',
					password_confirmation: 	''
				}
			}
		},
		validations()
		{
			return {
				form: 	{
					name: 	{
						required
					},
					phone: 	{
						required
					},
					password: 	{
						required,
						minLength: minLength(6)
					},
					password_confirmation: 	{
						sameAsPassword: sameAs(this.form.password)
					}
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			ValidateMailToken()
			{
				this.StartLoading()

				const vm 			= this

				const validate_url 	= `${process.env.VUE_APP_API_URL}/api/landingpage/validate-mail-token/${vm.token}`

				return window.axios.get(validate_url)
					.then(({data}) => {

						if(data.response)
						{	
							this.FinishLoading()

							vm.user 		 	= data.user

							vm.form.name 		= data.user.name
							vm.form.email 		= data.user.email

						}else{

							throw new Error(data.message)
						}
					})
					.catch(error => {

						switch(error)
						{
							case 'invalid_mail_token':
								Swal.fire({
									icon: 				'error',
									title: 				'Ops...',
									text: 				'Invalid token, contact support.',
									showConfirmButton: 	true,
									preConfirm: () => {
										vm.$router.push({ name: 'Home' })
									}
								})

							break;
							default:

								Swal.fire({
									icon: 				'error',
									title: 				'Ops...',
									text: 				'An error has occurred, contact support.',
									showConfirmButton: 	true,
									preConfirm: () => {
										vm.$router.push({ name: 'Home' })
									}
								})
							break
						}
						
					})
			},
			CompleteThisRegister()
			{
				const vm 		= this

				vm.v$.form.$touch()

				if(!vm.v$.form.$invalid)
				{
					vm.StartLoading()

					const register_url 	= `${process.env.VUE_APP_API_URL}/api/landingpage/complete-this-register`

					window.axios.post(register_url, {
							...vm.form,
							user_id: 	vm.user.id,
							token: 		vm.token
						})
						.then(({data}) => {
							if(data.response)
							{
								Swal.fire({
									icon: 				'success',
									title: 				'Excellent !',
									text: 				'Now, you can access our platform and features, log in.',
									showConfirmButton: 	true,
									preConfirm: () => {
										vm.$router.push({ name: 'Home' })
									}
								})

							}else{

								throw new Error(data.message)
							}
						})	
						.catch(error => {
							Swal.fire({
								icon: 	'error',
								title: 	'Ops...',
								text: 	'An error has occurred, contact support.'
							})
						})
						.finally(() => {
							vm.FinishLoading()
						})
				}
			}
		},
		beforeMount()
		{
			this.ValidateMailToken()
		}
	}
</script>

<style lang="css" scoped>
</style>