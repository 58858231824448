import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'

NProgress.configure({
    showSpinner: false
});

const router = createRouter({
    history:   createWebHistory(),
    routes: [
        {
            path: '/:pathMatch(.*)*',
            redirect: {
                name: 'Home'
            }
        },
        {
            path: '/',
            component: require('./components/layout/Index.vue').default,
            children: [
                {
                    path: '/',
                    component: require('./components/layout/EmptyParent.vue').default,
                    children: [
                        {
                            name: 'Home',
                            path: '',
                            component: require('./components/home/Index.vue').default,
                            default: true,
                            meta: {
                                title: 'Home'
                            }
                        },
                        {
                            path: '',
                            redirect: {
                                name: 'Home'
                            }
                        }
                    ]
                },
                {
                    path: '/',
                    component: require('./components/layout/FormParent.vue').default,
                    children: [
                        {
                            name: 'ValidateRegistration',
                            path: 'register/token/:token',
                            props: true,
                            component: require('./components/forms/ValidateRegistration.vue').default,
                            meta: {
                                title: 'Validate this registration'
                            }
                        },
                        {
                            name: 'ValidateEmail',
                            path: 'validate-mail/token/:token',
                            props: true,
                            component: require('./components/forms/ValidateEmail.vue').default,
                            meta: {
                                title: 'Validate my new email'
                            }
                        },
                        {
                            name: 'Login',
                            path: 'login',
                            component: require('./components/forms/Login.vue').default,
                            meta: {
                                title: 'Login'
                            }
                        },
                        {
                            name: 'Register',
                            path: 'register',
                            component: require('./components/forms/Register.vue').default,
                            meta: {
                                title: 'Register'
                            }
                        },
                        {
                            path: '',
                            redirect: {
                                name: 'Login'
                            }
                        }
                    ]
                },
            ]
        }
    ]
});

router.beforeEach((to, from, next) => {

    NProgress.start()

    next()
})

router.afterEach((to) => {
    document.title = 'ERVISION | ' + to.meta.title

    NProgress.done()
})

export default router;